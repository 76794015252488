@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "ambitregular", sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #f7f8fb;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

#root {
  overflow: hidden;
}

.text-ambit-reguler {
  font-family: "ambitregular";
}

.text-ambit-bold {
  font-family: "ambitbold";
}

.text-ambit-semibold {
  font-family: "ambitsemibold";
}

.text-ambit-extralight {
  font-family: "ambitextralight";
}

.text-ambit-light {
  font-family: "ambitlight";
}

.text-ambit-thin {
  font-family: "ambitthin";
}

@media (max-width: 1024px) {
  .tablet-text-ambit-bold {
    font-family: "ambitbold";
  }
}

@media (max-width: 768px) {
  .text-ambit-light-phone {
    font-family: "ambitlight";
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  padding: 10px 16px;
  font-size: 14px;
  background-color: #0d1f42;
  font-weight: 700;
  border-radius: 4px;
  color: white;
}

@media (min-width: 3840px) {
  .btn {
    padding: 24px 32px;
    background-color: #0d1f42;
    font-weight: 700;
    border-radius: 10px;
    color: white;
  }
}

.shadow-header {
  box-shadow: 0px 4px 40px -18px rgba(0, 0, 0, 0.32);
}

.stand-card {
  display: flex;
  flex-direction: column;
  transition: 0.4s ease;
  height: 280px;
}

@media (max-width: 1024px) {
  .stand-card {
    max-width: 201px;
    height: 248px;
  }
}

@media (max-width: 768px) {
  .stand-card {
    max-width: 100% !important;
    height: auto;
  }
}

.stand-card:hover {
  box-shadow: -4px 4px 0px rgba(0, 0, 0, 1);
}

.landing-line-custom::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 11px;
  height: 11px;
  transform: translate(-5px, 11px);
  border-radius: 10000px;
  background-color: rgba(255, 255, 255, 0.56);
}

.landing-line-custom::after {
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  transform: translate(-5px, -12px);
  border-radius: 10000px;
  background-color: rgba(255, 255, 255, 0.56);
}

@media (min-width: 3840px) {
  .landing-line-custom::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 25px;
    height: 25px;
    transform: translate(-5px, 11px);
    border-radius: 10000px;
    background-color: rgba(255, 255, 255, 0.56);
  }
  .landing-line-custom::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    left: -6px;
    top: -8px;
    transform: translate(-5px, -12px);
    border-radius: 10000px;
    background-color: rgba(255, 255, 255, 0.56);
  }
}

.about-team-detail-text::-webkit-scrollbar {
  width: 6px;
}

.about-team-detail-text::-webkit-scrollbar {
  background: white;
}

.about-team-detail-text::-webkit-scrollbar-thumb {
  background: #babbbf;
  border-radius: 40px;
}

.about-team-detail-text::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.client-logo {
  opacity: 1;
  transform: translateY(0px);
}

.client-logo.show {
  opacity: 1;
  transform: translateY(0px);
}

@media (min-width: 1440px) {
  /* #azapak {
    grid-column: 1/2;
  }
  #fienza {
    grid-column: 3/4;
  } */
}

.how-it-work-item {
  opacity: 0;
}

.landing-card {
  transition: 0.4s ease;
}

.landing-card:hover {
  box-shadow: -4px 4px 0px rgba(0, 0, 0, 1);
}

.fadeIn {
  animation: fadeInAnimation 1s linear;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.swiper {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1440px) {
  .swiper {
    padding-left: 5px;
    padding-right: 5px;
  }
}

#landing-path {
  stroke-dasharray: 552.8171997070312 552.8171997070312;
  stroke-dashoffset: 552.8171997070312;
  /* animation-name: draw;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear; */
}

/* @keyframes draw {
  0% {
    stroke-dashoffset: 552.8171997070312;
  }
  100% {
    stroke-dashoffset: 0;
  }
} */

.tooltip {
  background-color: white;
  border: 1px solid black;
  padding: 6px 8px;
  box-shadow: -2px 2px 0px rgba(0, 0, 0, 1);
  position: absolute;
  border-radius: 6px;
  width: 120px;
}

.tooltip-phone {
  top: 30px;
  left: -25px;
}

.tooltip.tooltip-linkedin {
  width: 140px;
}

.tooltip-linkedin {
  top: 30px;
  left: -60px;
}

@media (max-width: 1024px) {
  .tooltip-linkedin {
    left: -60px;
  }
}

@media (max-width: 375px) {
  .tooltip-phone {
    left: -30px;
  }
}

@media (min-width: 1024px) {
  #fienza {
    grid-column: 2;
  }
}

@media (min-width: 1440px) {
  #fienza {
    grid-column: 3;
  }
}

@media (min-width: 3840px) {
  /* #stairlock img,
  #cult img,
  #fienza img {
    transform: translateX(190px);
  } */
  #dominant,
  #fienza,
  #cult {
    transform: translateX(150px);
    padding: 80px 0px;
  }
}
.btn-hover:hover svg path {
  fill: white;
}
.show-down {
  animation: showDown 1s ease forwards;
}

.show-gone {
  animation: showGone 1s ease forwards;
}

@keyframes showDown {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes showGone {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-200px);
  }
}

.what-we-stand-for-img {
  box-shadow: -6px 6px 0px rgba(0, 0, 0, 1);
}

.input-field:focus {
  box-shadow: -2px 2px 0px rgba(0, 0, 0, 1);
}

@media (min-width: 3840px) {
  .input-field {
    font-size: 30px !important;
  }
  .input-field::placeholder {
    font-size: 30px;
  }
}

.location-item .checkbox {
  background: rgb(235, 235, 235);
}

.fade-bottom {
  animation: fadeBottom 1s ease forwards;
}

@keyframes fadeBottom {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.job_description {
  width: 100%;
}

.job_description p,
.job_description strong {
  font-family: "ambitregular" !important;
  font-size: 16px !important;
  line-height: 150% !important;
}

.job_description ul {
  list-style: disc !important;
  padding-left: 20px !important;
}

.job_description ul li {
  margin-bottom: 8px !important;
  color: #0d1f42 !important;
  font-family: "ambitregular" !important;
  font-size: 16px !important;
  line-height: 1.4 !important;
}

select {
  -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.swiper-slide {
  padding-bottom: 4px !important;
}
